import { Link } from '@blackbird/ui-base';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import LoadingOrError from '@/components/loading/loading-or-error';
import { UserContext } from '@/contexts/user';
import { WithStyles, withStyles } from '@/hocs/with-styles';
import { makeModuleAccessChecker } from '@/hooks/use-iam';
import * as Feature from '@/lib/features';
import * as Types from '@/types';
import { Module } from '@/types/auth-types';

export interface WithIAM {
  user: Partial<Types.ExtendedUser>;
  features: Feature.FeatureMap;
  hasPeripheralFeatures: Feature.HasItemFeatures;
  hasGroupFeatures: Feature.HasItemFeatures;
  hasLineFeatures: Feature.HasItemFeatures;
  hasFeatureInAnyGroup: (lookupFeatures: string[], featureType: 'M' | 'Q') => boolean;
  hasFeatureInAnyUserPool: (lookupFeatures: string[], featureType: 'M' | 'Q') => boolean;
  hasModuleAccess: (module: Module) => boolean;
}

// FIXME: Correct the user context signature...

// tslint:disable:max-classes-per-file
export const withIAM = (WrappedComponent: React.ComponentType<WithIAM>) => {
  return class WithIAMWrapper extends React.Component {
    public render() {
      return (
        <UserContext.Consumer>
          {(user) => (
            <WrappedComponent
              user={user}
              features={(user as any).features as Feature.FeatureMap}
              hasPeripheralFeatures={Feature.generateForItem((user as any).features, 'peripheral')}
              hasGroupFeatures={Feature.generateForItem((user as any).features, 'group')}
              hasLineFeatures={Feature.generateForItem((user as any).features, 'line')}
              hasFeatureInAnyGroup={Feature.inAnyGroup((user as any).features)}
              hasFeatureInAnyUserPool={Feature.inAnyUserPool((user as any).features)}
              hasModuleAccess={makeModuleAccessChecker(user.claims)}
              {...this.props}
            />
          )}
        </UserContext.Consumer>
      );
    }
  };
};
const styles = (theme: Theme) => ({
  card: {
    width: '100%',
    maxWidth: '500px',
  },
  fillHeight: {
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
});

export const withIAMPage =
  (lookupFeatures: string[], featureType: 'M' | 'Q', scope: 'group' | 'userPool' = 'group') =>
  <P extends Readonly<{}>, T extends React.ComponentClass<P>>(WrappedComponent: T) => {
    type WrappedProps = JSX.LibraryManagedAttributes<T, P>;

    class WithIAMPageWrapper extends React.Component<WithTranslation & WithStyles<typeof styles>, {}> {
      public render() {
        const { classes, t, i18n, tReady, ...restProps } = this.props;

        return (
          <UserContext.Consumer>
            {({ status, ...user }) => {
              const allowed =
                scope === 'userPool'
                  ? Feature.inAnyUserPool((user as any).features)(lookupFeatures, featureType)
                  : Feature.inAnyGroup((user as any).features)(lookupFeatures, featureType);

              let wrappedMessage;

              if (!status.loading && user && (user as any).features && !allowed) {
                wrappedMessage = (
                  <Grid item xs={12} className={classes.fillHeight}>
                    <Grid container alignItems="center" justifyContent="center" className={classes.fillHeight}>
                      <Grid item>
                        <Card className={classes.card}>
                          <CardContent>
                            <Typography variant="h5" className={classes.title}>
                              {t(['shared:unfortunatelyNoPermission'], {
                                defaultValue: "Unfortunately, you don't have permission to view this page!",
                              })}
                            </Typography>
                            <Typography>
                              {t(['shared:contactYourLocalAdminIfWrong'], {
                                defaultValue:
                                  'If you believe this is a mistake, contact your local administrator, and they can grant you access.',
                              })}
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Link href={{ pathname: '/' }}>
                              <Button color="primary">
                                {t(['shared:returnToFrontPage'], { defaultValue: 'Return to front page' })}
                              </Button>
                            </Link>
                          </CardActions>
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              }

              return (
                <LoadingOrError loading={status.loading} error={status.error} wrappedMessage={wrappedMessage}>
                  <WrappedComponent
                    features={(user as any).features}
                    hasPeripheralFeatures={Feature.generateForItem((user as any).features, 'peripheral')}
                    hasGroupFeatures={Feature.generateForItem((user as any).features, 'group')}
                    hasLineFeatures={Feature.generateForItem((user as any).features, 'line')}
                    hasFeatureInAnyGroup={Feature.inAnyGroup((user as any).features)}
                    hasModuleAccess={makeModuleAccessChecker(user.claims)}
                    {...(restProps as unknown as WrappedProps)}
                  />
                </LoadingOrError>
              );
            }}
          </UserContext.Consumer>
        );
      }
    }
    const enhance = compose<unknown, {}>(withTranslation(['shared']), withStyles(styles));
    return enhance(WithIAMPageWrapper as React.ComponentType<unknown>);
  };
