import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { ButtonBase } from '@mui/material';
import { ButtonBaseProps } from '@mui/material/ButtonBase';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()({
  fadeScrollButton: {
    color: 'rgba(0, 0, 0, .1)',
  },
  scrollButton: {
    width: 56,
    flexShrink: 0,
  },
});

interface ScrollButtonProps extends ButtonBaseProps {
  className: string;
  direction: string;
  visible: boolean;
}

const ScrollButton: React.FC<ScrollButtonProps> = (props) => {
  const { className, onClick, direction, visible, disabled, ...other } = props;

  const { classes, cx } = useStyles();

  const classNames = disabled
    ? cx(!visible && classes.fadeScrollButton, classes.scrollButton, className)
    : cx(classes.scrollButton, className);

  return (
    <ButtonBase className={classNames} onClick={onClick} tabIndex={-1} disabled={disabled} {...other}>
      {direction === 'left' ? <KeyboardArrowLeft fontSize={'large'} /> : <KeyboardArrowRight fontSize={'large'} />}
    </ButtonBase>
  );
};

export default ScrollButton;
