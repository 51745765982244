import { useLazyQuery } from '@apollo/client';
import { Typography, useMediaQuery } from '@mui/material';
import * as Schema from 'generated/graphql/schema';
import React, { FC, useEffect, useMemo } from 'react';
import { makeStyles } from 'tss-react/mui';

import { useTimePicker } from '@/contexts/timepicker';
import { shiftTimes } from '@/graphql/queries';
import { timeFormathhmm, timeFormatyyyyMMdhmm } from '@/helpers/helper-functions';
import { getDayStringOfEnum } from '@/helpers/schedule';
import { convertGenericShiftTimeToDatePair, getYearWeek } from '@/helpers/schedule';
import { useLine, useTranslation } from '@/hooks';

const useStyles = makeStyles()((theme) => ({
  range: {
    fontSize: '75%',
    color: theme.palette.grey.A400,
  },
  timeSelector: {
    minWidth: 60,
    maxWidth: 60,
  },
  selectedTimeText: {
    color: theme.palette.primary.main,
  },
  timePickerSelectedTime: {
    marginLeft: '1em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.5em',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '1em',
    },
  },
  hiddenOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}));

const SelectedTimeRange: FC = () => {
  const { classes } = useStyles();
  const [t, i18n] = useTranslation(['shared']);
  const { from, to } = useTimePicker();
  const { lineId } = useLine();

  const isPrint = useMediaQuery('@media print');
  const currentDate = useMemo(() => new Date(), [from, to]);
  const nowFilter = getYearWeek(currentDate);

  const [getShifts, { data: nextData, previousData }] = useLazyQuery<
    Schema.ShiftTimesQuery,
    Schema.ShiftTimesQueryVariables
  >(shiftTimes, {
    ssr: false,
    partialRefetch: true,
    variables: {
      lineId: lineId!,
      validIn: nowFilter,
    },
  });

  const data = nextData || previousData;

  useEffect(() => {
    if (!lineId) {
      return;
    }

    if (!isPrint) {
      return;
    }

    getShifts();
  }, [lineId, isPrint]);

  const startDay = data?.line.schedule?.configuration?.startDayOfWeek ?? Schema.DayOfWeek.MONDAY;

  const perfectlyAlignedShift = data?.line.schedule?.shifts.find((shift) => {
    if (!to) {
      return false;
    }

    const { from: startDate, to: endDate } = convertGenericShiftTimeToDatePair(shift.timeRange, currentDate, startDay);

    return from.getTime() === startDate.getTime() && to.getTime() === endDate.getTime();
  });

  const alignedSelection = perfectlyAlignedShift
    ? `${getDayStringOfEnum(t, perfectlyAlignedShift.timeRange.from.day)}: ${perfectlyAlignedShift.name}`
    : '';

  if (isPrint) {
    const terminatingText = to ? timeFormathhmm(to, i18n.language) : timeFormatyyyyMMdhmm(currentDate, i18n.language);
    return (
      <Typography variant="h6">
        {alignedSelection ? `${alignedSelection} ` : ''}
        <span className={classes.range}>
          {timeFormatyyyyMMdhmm(from, i18n.language)} &ndash; {terminatingText}
        </span>
      </Typography>
    );
  }

  return (
    <Typography className={classes.timePickerSelectedTime} variant="body2">
      <span className={classes.hiddenOnMobile}>{t(['shared:selected'], { defaultValue: 'Selected' })}: </span>
      <span className={classes.selectedTimeText}>{timeFormatyyyyMMdhmm(from, i18n.language)}</span>{' '}
      {t(['shared:to'], { defaultValue: 'to' }).toLowerCase()}{' '}
      <span className={classes.selectedTimeText}>
        {to ? timeFormatyyyyMMdhmm(to, i18n.language) : t(['shared:now'], { defaultValue: 'now' }).toLowerCase()}
      </span>{' '}
      {alignedSelection ? `(${alignedSelection})` : ''}
    </Typography>
  );
};

export default SelectedTimeRange;
