import { Undo } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import ScrollButton from '@/components/buttons/scroll-button';
import CustomDateTimePicker from '@/components/pickers/date-time-range-picker';
import SelectedTimeRange from '@/components/selected-time-range';
import * as Constants from '@/constants';
import { timeSelectionIntervals, useTimePicker } from '@/contexts/timepicker';

const useStyles = makeStyles()((theme) => ({
  timeSelector: {
    overflow: 'visible',
    minWidth: 60,
    maxWidth: 60,
    [theme.breakpoints.down('sm')]: {
      minWidth: 45,
      maxWidth: 45,
    },
  },
  customTimeIcon: {
    height: '16px',
  },
  timePickerTabs: {
    overflow: 'hidden',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: '0 !important',
    },
  },
  tabsScroller: {
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      width: '0 !important',
    },
  },
  timePickerSelectedTime: {
    marginLeft: '1em',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('lg')]: {
      marginTop: '0.5em',
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '1em',
    },
  },
  flex: {
    flex: 1,
    overflow: 'hidden',
    marginTop: theme.spacing(-1),
  },
  hiddenOnMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  hidden: {
    minWidth: 60,
    maxWidth: 60,
    visibility: 'hidden',
  },
}));

interface Properties {
  hideSelected?: boolean;
  includeLargeRanges?: boolean;
  showCustomTimePicker?: boolean;
  showUndo?: boolean;
}

const TimePicker: FunctionComponent<Properties> = ({
  hideSelected = false,
  includeLargeRanges,
  showCustomTimePicker = true,
  showUndo = true,
}) => {
  const { from, to, setTime, historySize, undo, relativeTimeInterval } = useTimePicker();

  const { classes } = useStyles();
  const [t] = useTranslation(['shared']);

  const calendarIconRef = useRef(null);

  const [open, setOpen] = useState(false);

  const filteredTimeSelectionIntervals = useMemo(
    () =>
      timeSelectionIntervals.filter(
        ({ largeRange }) => largeRange === undefined || (includeLargeRanges && largeRange === true),
      ),
    [includeLargeRanges],
  );

  const onTimeSelect = useCallback(
    (_: unknown, v: string) => {
      const selectedTimeInterval = filteredTimeSelectionIntervals[Number(v)];
      if (!selectedTimeInterval) {
        return;
      }

      setTime(new Date(Date.now() - selectedTimeInterval.time), null);
    },
    [setTime, filteredTimeSelectionIntervals],
  );

  const onClickCalendarIcon = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const onCalendarSelect = useCallback<typeof setTime>((start, end) => {
    setTime(start, end);
    onClose();
  }, []);

  const numSelectableTabs = filteredTimeSelectionIntervals.length + 1;

  // relativeTimeInverval is not 100% exact, so +-1 second
  const value =
    ((filteredTimeSelectionIntervals.findIndex(
      ({ time }) => relativeTimeInterval && Math.abs(relativeTimeInterval - time) < 1000,
    ) %
      numSelectableTabs) +
      numSelectableTabs) %
    numSelectableTabs;

  return (
    <div className={classes.flex}>
      <Tabs
        indicatorColor="primary"
        className={classes.timePickerTabs}
        classes={{ scroller: classes.tabsScroller }}
        value={value}
        onChange={onTimeSelect}
        variant="scrollable"
        scrollButtons="auto"
        ScrollButtonComponent={ScrollButton}
        allowScrollButtonsMobile
      >
        {filteredTimeSelectionIntervals.map(({ label }) => {
          return (
            <Tab className={classes.timeSelector} key={label} label={t([`shared:${label}`], { defaultValue: label })} />
          );
        })}
        {showCustomTimePicker && (
          <Tab
            className={classes.timeSelector}
            key="custom"
            icon={<Constants.CALENDAR_PICKER_ICON className={classes.customTimeIcon} />}
            ref={calendarIconRef}
            onClick={onClickCalendarIcon}
          />
        )}
        {showUndo && (
          <Tab
            className={!!historySize ? classes.timeSelector : classes.hidden}
            key="time-history"
            icon={<Undo className={classes.customTimeIcon} />}
            onClick={undo}
          />
        )}
      </Tabs>
      {!hideSelected && <SelectedTimeRange />}
      <CustomDateTimePicker
        anchorEl={calendarIconRef.current}
        disableFuture
        from={from}
        to={to || new Date()}
        open={open}
        closePicker={onClose}
        setTime={onCalendarSelect}
        range
        showTabs
      />
    </div>
  );
};

export default TimePicker;
