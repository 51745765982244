import { createContextHook } from '@blackbird/ui-base/contexts';
import { useRouter } from 'next/router';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { format } from 'url';

import { useURLQuery } from '@/hooks';

export const timeSelectionIntervals = [
  { time: (30).minutes, label: '30m' },
  { time: (60).minutes, label: '1h' },
  { time: (4).hours, label: '4h' },
  { time: (8).hours, label: '8h' },
  { time: (12).hours, label: '12h' },
  { time: (24).hours, label: '24h' },
  { time: 2 * (24).hours, label: '48h' },
  { time: 7 * (24).hours, label: '1w' },
  { time: 30 * (24).hours, label: '1m', largeRange: true },
];

const [, , { time: defaultInterval }] = timeSelectionIntervals;

export interface Context {
  from: Date;
  to: Date | null;
  historySize: number;
  setTime: (start: Date, end: Date | null) => void;
  undo: () => number | undefined;
  relativeTimeInterval: typeof defaultInterval | null;
}

export const [TimePickerProvider, useTimePicker] = createContextHook<Context>();

interface Properties {
  children: React.ReactNode;
}

const TimepickerContextWrapper: FunctionComponent<Properties> = ({ children }) => {
  const { from: queryFrom = '', to: queryTo = '', ...restQuery } = useURLQuery();

  const [queryFromMillis, queryToMillis] = useMemo(
    () => [new Date(queryFrom).getTime() || NaN, new Date(queryTo).getTime() || NaN],
    [queryFrom, queryTo],
  );
  const [from, setFrom] = useState(queryFromMillis || Date.now() - (8).hours);
  const [to, setTo] = useState<number | null>(queryFromMillis < queryToMillis ? queryToMillis : null);
  const [history, setHistory] = useState<Array<{ from: number; to: number | null }>>([]);
  const relativeTimeInterval = to ? null : Date.now() - from;

  const setTime = useCallback(
    (start: Date, end: Date | null) => {
      // Ensures from and to are updated at the same time. May be removed in future React versions. https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
      unstable_batchedUpdates(() => {
        setFrom(start.getTime());
        setTo(end && start.getTime() < end.getTime() ? end.getTime() : null);
        setHistory([...history, { from, to }].slice(-10));
      });
    },
    [history],
  );

  useEffect(() => {
    // Ensures from and to are updated at the same time. May be removed in future React versions. https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
    unstable_batchedUpdates(() => {
      if (queryFromMillis && queryFromMillis !== from) {
        setFrom(queryFromMillis);
      }

      if (queryToMillis && queryToMillis !== to) {
        setTo(queryToMillis);
      }
    });
  }, [queryFromMillis, queryToMillis]);

  const router = useRouter();

  useEffect(() => {
    const newFrom = queryFromMillis !== from;
    const newTo = queryToMillis !== to;

    if (!newFrom && !newTo) {
      return;
    }

    const { pathname } = router;

    const url = format({
      pathname,
      query: [{ from }, { to }].reduce((query, timestamp) => {
        const [[key, value]] = Object.entries(timestamp);

        if (value) {
          query[key] = new Date(value).toISOString();
        }

        return query;
      }, restQuery as Record<string, string>),
    });

    router.replace(url, url, { shallow: true });
  }, [from, to]);

  const undo = useCallback(() => {
    if (!history.length) {
      return;
    }

    const cutOff = history.length - 1;

    const [nextHistory, [historyItem]] = [history.slice(0, cutOff), history.slice(cutOff)];

    // Ensures from and to are updated at the same time. May be removed in future React versions. https://stackoverflow.com/questions/48563650/does-react-keep-the-order-for-state-updates/48610973#48610973
    unstable_batchedUpdates(() => {
      setHistory(nextHistory);

      if (!historyItem) {
        return;
      }

      setFrom(historyItem.from);
      setTo(historyItem.to);
    });

    return historyItem.from;
  }, [history]);

  const futureTimeSpan = Math.max(0, (to || 0) - Date.now());

  const fromDate = useMemo(() => new Date(from), [from, futureTimeSpan]);
  const toDate = useMemo(() => (to ? new Date(to) : null), [to, futureTimeSpan]);

  return (
    <TimePickerProvider
      value={{
        from: fromDate,
        to: toDate,
        setTime,
        historySize: history.length,
        undo,
        relativeTimeInterval,
      }}
    >
      {children}
    </TimePickerProvider>
  );
};

export default TimepickerContextWrapper;
